import React, { Component } from "react"
import PropTypes from "prop-types"

import styled from 'styled-components';
import device from '../utils/media';
import theme from '../utils/theme'


// Burger
const Burger = styled.div`
    background: ${theme.primary};
    padding: ${2.5 * theme.burgerSize}px;
    height: ${15 * theme.burgerSize}px;
    width: ${15 * theme.burgerSize}px;
    border-radius: ${2 * theme.burgerSize}px;

    @media ${device.laptop} {
        display: none;
    }  
`;

const BurgerButton = styled.a`
    width: ${10 * theme.burgerSize}px;
    height: ${10 * theme.burgerSize}px;
    display: block;
    position: relative;
    margin-top: ${theme.burgerSize}px;
`;

const BurgerSpan = styled.span`
    height: ${theme.burgerSize}px;
    position: absolute;
    background: #fff;
    text-indent: -999em;
    width: 100%;
    top: ${3 * theme.burgerSize}px;
    transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);

    &:before, &:after {
        content: '';
        height: ${theme.burgerSize}px;
        background: #fff;
        width: 100%;
        position: absolute;
        top: ${-3 * theme.burgerSize}px;
        left: 0;
        transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
    }

    &:after {
        top: ${3 * theme.burgerSize}px;
    }

    ${BurgerButton}.active & {    
        background: transparent;

        &:before {
            transform: translateY(${3 * theme.burgerSize}px) translateX(${0.5 * theme.burgerSize}px) rotate(45deg);
        }

        &:after {
          transform: translateY(${-3 * theme.burgerSize}px) translateX(${0.5 * theme.burgerSize}px) rotate(-45deg);
        }
    }
`;

// Main menu
const MainMenuWrapper = styled.div`
    position: absolute;
    bottom: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    z-index: 20;
    background: ${theme.primary};
    
    @media ${device.laptop} {
        background: ${theme.menu};
    }

    &.is_top {
        position: fixed;
        left: 0px;
        top: 0px;
        bottom: auto;
        width: 100vw;

        @media ${device.laptop} {
            flex-direction: row;    
            align-items: flex-end;
        }
    }    

    @media ${device.laptop} {
        display: flex;
        flex-flow: row;
        align-items: flex-end;  
    }

`;

// Menulist
const MenuListWrapper = styled.div`
    width: 100vw;
    height: 0;
    box-sizing: content-box;
    color: ${theme.primary};
    text-transform: uppercase;

    transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);

    @media ${device.laptop} {
        height: auto;
        display: block;
    }

    &.active {
        height: auto;
    }
`;

const MenuList = styled.ul`
    margin: 0;

    display: none;

    @media ${device.laptop} {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;  
        padding: 0 3em;
    }


    @media ${device.laptopL} {
        padding 0px 50px 0px 50px;
    }

    ${MenuListWrapper}.active & {
        display: block;
        position: absolute;
        width: 100%;
        background: ${theme.primary};
    }

    ${MainMenuWrapper}.is_top & {
    
    }

`;

const ListItem = styled.li`
    list-style-type: none;
    text-align: center;
    margin: 0;
`;

const ListItemLink = styled.a`
    display: inline-flex;
    text-decoration: none;
    color: white;
    font-size: 1.3em;
    padding: 0.5em 1em; 
    border: 1px solid ${theme.primary};
    border-bottom:none;
    width: 80%;

    @media ${device.laptop} {
        color: ${theme.primary};
    }

    @media ${device.laptopL} {
        font-size: 25px;
    }

    &:hover {
        border: 1px solid ${theme.primary};
        background: ${theme.primary};
        color: #fff;
        border-bottom:none;
        }

    &.active {
        color: ${theme.primary};
        background: white;
        border: 1px solid white;
    }

    ${MainMenuWrapper}.is_top & {
    
        @media ${device.laptopL} {
            font-size: 1.3em;
        }
    }

`;

const ListLink = props => (
    <ListItem>
        <ListItemLink className={(props.active === props.to) ? 'active' : '' } href={props.to}>{props.children}</ListItemLink>
    </ListItem>
)

// Brand
const BrandWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

`;

const BrandImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.primary};
`;

const BrandImage = styled.img`
    padding: 0;
    margin: 0;
    transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);

    @media ${device.tablet} {
        height: 100px;
    }

    ${MainMenuWrapper}.is_top & {
        height: 60px;

        @media ${device.laptopL} {
            height: 81px;
        }

    }


`;

class MainMenu extends Component {
    state = {
        isMenuShown: false,
        brandPosition: 0,
        virtualTop: 0,
        isTop: false,
    };

    componentDidMount()
    {
        const { allwaysTop } = this.props;
        this.setState(() => ({isTop:allwaysTop}));

        let me = this;
    
        // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
        if (typeof window !== 'undefined') {

            var rect = document.getElementById('brand_div').getBoundingClientRect();
            var position = {
                top: rect.top + window.pageYOffset,
                left: rect.left + window.pageXOffset
            };

            console.log("top: " + rect.top + " height: " + position.top + " window.pageYOffset: " + window.pageYOffset + " window.innerHeigth: " + window.innerHeight);


          window.onscroll = function () {
            let rect = document.getElementById('brand_div').getBoundingClientRect();
    //        console.log("top: " + rect.top + " window.pageYOffset: " + window.pageYOffset + " virtualTop " + me.state.virtualTop + " isTop: " + me.state.isTop);

            if (rect.top < 1)
            {
                if (me.state.isTop === false)
                {
                    me.setState(() => ({virtualTop: window.pageYOffset}));
                    me.setState(() => ({isTop: true}));    
                }
            } 
            
            if(window.pageYOffset < me.state.virtualTop)
            {
                if (! allwaysTop)
                {
                    me.setState(() => ({isTop: false}));    
                }
            }
          }
        }
    }

    handleClick = () => {
        this.setState(state => ({ isMenuShown: !state.isMenuShown }));
    };

    render() {
        const { logo, entries, active } = this.props;

        return (
            <MainMenuWrapper id='brand_div' className={(this.state.isTop) ? 'is_top' : ''}>

                <BrandWrapper>
                    <Burger>
                        <BurgerButton className={(this.state.isMenuShown) ? 'active' : ''} onClick={this.handleClick}>
                            <BurgerSpan>Menu</BurgerSpan>
                        </BurgerButton>
                    </Burger>
                    <BrandImageWrapper>
                        <a href='/'>
                            <BrandImage src={logo} alt="Logo" />
                        </a>
                    </BrandImageWrapper>
                </BrandWrapper>

                <MenuListWrapper className={(this.state.isMenuShown) ? 'active' : ''}>
                    <MenuList>
                        {entries.map((entry, key) => 
                            <ListLink key={key} to={entry.link} active={active}>{entry.name}</ListLink>
                        )}
                    </MenuList>
                </MenuListWrapper>
            </MainMenuWrapper>
        )
    }
}

MainMenu.propTypes = {
    logo: PropTypes.node.isRequired,
}

MainMenu.defaultProps = {
    allwaysTop: false,
}
export default MainMenu