import { createGlobalStyle } from 'styled-components'

import theme from '../utils/theme'

const GlobalStyle =  createGlobalStyle`


h1,h2,h3,h4,h5,h6 {
    color: ${theme.primary};
}
`

export default GlobalStyle
