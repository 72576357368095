const theme = {
    primary: '#004f7c',
    menu: '#eee',
    bg: 'white',
    dark: 'rgba(0, 0, 0, 0.9)',
    default: 'rgba(0, 0, 0, 0.7)',
    light: 'rgba(0, 0, 0, 0.6)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
    transitionTime: '0.5s',
    fontSmall: '0.9rem',
    burgerSize: 3,
  };
  
  export default theme;